.Tweets {
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #cols {
    -moz-column-count: 4;
    -moz-column-gap: 50px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
    column-gap: 20px;
}

@media screen and (max-width: 960px) {
  .Tweets {
    height: 50%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }}


@media screen and (max-width: 960px) {
  #cols {
    -moz-column-count: 1;
    -moz-column-gap: 50px;
    -webkit-column-count: 1;
    -webkit-column-gap: 20px;
    column-count: 1;
    column-gap: 20px;
    margin-right: 50px;
    align-items: left;
    
  }}